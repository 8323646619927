import {
  convertAreaToCustom,
  convertAreaToDefault,
  UnitSymbol,
} from "@ero/app-common/util/convertArea";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

import { SIZE_FIELD } from "@ero/app-common/enums/sizeField";
import { UNIT } from "@ero/app-common/util/Units";
import { Input } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowParams,
  GridValueSetterParams,
} from "@mui/x-data-grid";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ImportParcelTableRow } from "Screens/parcels/types";
import { AppState } from "Store";
import { LanguageType, OptionType } from "Types";
import EditAutocomplete from "./editAutocomplete";

export const useColumns = (
  cropOptions: OptionType[],
  editRowId: GridRowId | undefined,
  onEditClick: (id: GridRowId) => void,
  onSaveClick: (id: GridRowId) => void,
  onCancelClick: (id: GridRowId) => void,
  disableActionButtons: boolean,
): GridColDef[] => {
  const [t] = useTranslation();

  const selectedLang = useSelector(
    (state: AppState) => state.auth.selectedLang,
  ) as LanguageType;

  const unitOfMeasurement =
    useSelector(
      (store: AppState) => store.auth.companyData.unitOfMeasurement,
    ) ?? UNIT.HEKTAR;

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: t("general.fields.name"),
        editable: true,
      },
      {
        field: "size",
        maxWidth: 80,
        headerName: t("parcels.fields.size_unit", {
          unit: UnitSymbol[unitOfMeasurement],
        }),
        align: "center",
        type: "number",
        editable: true,
        valueGetter: (params: GridValueSetterParams<ImportParcelTableRow>) =>
          convertAreaToCustom(params.value, unitOfMeasurement),
        valueSetter: ({
          value,
          row,
        }: GridValueSetterParams<ImportParcelTableRow>) => {
          const size = convertAreaToDefault(
            value,
            unitOfMeasurement,
            selectedLang,
          );

          const sizeChanged = size !== row.size;

          const sizeField = sizeChanged
            ? SIZE_FIELD.SIZE_MANUAL
            : row.sizeField;

          const sizeManual = sizeChanged
            ? convertAreaToDefault(size, unitOfMeasurement, selectedLang)
            : row.sizeManual;
          return { ...row, size, sizeField, sizeManual };
        },
      },
      {
        field: "regionette",
        minWidth: 150,
        headerName: t("orders.fields.regionette"),
        editable: true,
      },
      {
        field: "mark",
        minWidth: 150,
        headerName: t("orders.fields.mark"),
        editable: true,
      },
      {
        field: "rowAmount",
        maxWidth: 80,
        headerName: t("orders.labels.rows"),
        type: "number",
        headerAlign: "left",
        editable: true,
        renderEditCell: (params) => (
          <Input
            {...params}
            id={params.id.toString()}
            type="number"
            onChange={(event) => {
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: event.target.value,
              });
            }}
            inputProps={{
              min: 0,
            }}
            disableUnderline
          />
        ),
      },
      {
        field: "crop",
        minWidth: 150,
        headerName: t("orders.fields.crop"),
        type: "singleSelect",
        editable: true,
        renderEditCell: (params) => (
          <EditAutocomplete cropOptions={cropOptions} {...params} />
        ),
        valueOptions: cropOptions,
      },
      {
        field: "actions",
        type: "actions",
        hideable: false,
        headerName: t("parcels.fields.actions"),
        cellClassName: "actions",
        getActions: ({ id }: GridRowParams<ImportParcelTableRow>) => {
          const isInEditMode = editRowId === id;
          if (!isInEditMode) {
            return [
              <GridActionsCellItem
                key="editBtn"
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={() => void onEditClick(id)}
                color="inherit"
              />,
            ];
          }
          return [
            <GridActionsCellItem
              key="saveBtn"
              icon={<SaveIcon />}
              disabled={disableActionButtons}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={() => void onSaveClick(id)}
            />,
            <GridActionsCellItem
              key="cancelBtn"
              icon={<CancelIcon />}
              disabled={disableActionButtons}
              label="Cancel"
              className="textPrimary"
              onClick={() => void onCancelClick(id)}
              color="inherit"
            />,
          ];
        },
      },
    ],
    [
      cropOptions,
      disableActionButtons,
      editRowId,
      onCancelClick,
      onEditClick,
      onSaveClick,
      selectedLang,
      t,
      unitOfMeasurement,
    ],
  );
  return columns;
};
