import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { UserDataType } from "Types";

export const setSentryUser = (user: UserDataType | null) => {
  Sentry.setUser({ id: user?._id });
};

export const initSentry = (
  environment?: string,
  sentryDSN?: string,
  release?: string,
  environmentURL?: string,
) => {
  if (environment !== "development") {
    Sentry.init({
      dsn: sentryDSN,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      release: release,
      environment: environmentURL,
    });
  }
};
